import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PageTemplate = () => {
  return (
    <Layout>
      <SEO
        title="Quem Somos"
        description="Em nome de Allah, O Clemente, O Misericordioso Fundado em 2014, o Iqara Islam nasceu como um portal com o objetivo de difundir o conhecimento sobre a religião Islâmica aos falantes da língua portuguesa, abordando temas diversificados, que atendam a demanda do público por conhecimento, procurando sempre fornecer conteúdos únicos e soluções para o dia"
        url={`${process.env.GATSBY_HOST_URL}/quem-somos`}
      />

      <div
        class="content-grid page-grid direitos-dos-autores"
        style={{ maxWidth: "1100px", margin: "0 auto" }}
      >
        <div class="content content-custom">
          <h1 itemprop="name" itemtype="http://schema.org/Thing">
            Direitos dos Autores
          </h1>
          <div>
            <p>
              É proibido a cópia de qualquer conteúdo deste site sem a adequada
              referência ao Iqara Islam. Todo conteúdo nesse site que não é da
              autoria dos administradores tiveram a autorização dos respectivos
              autores. A cópia ilegal é crime e todas as medidas serão
              consideradas para proteger a propriedade intelectual. Caso deseje
              colocar um texto em seu site ou página, coloque a referência do
              link de onde foi extraído o texto. Dúvidas entre em{" "}
              <a href="https://iqaraislam.com/contato/">contato conosco.</a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate
